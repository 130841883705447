import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardText,[_c('crud',{attrs:{"model":'Concept',"create-permission":"Agregar Conceptos","edit-permission":"Editar Conceptos","delete-permission":"Eliminar Conceptos","resource":"concepts","filters":_vm.filters,"headers":_vm.headers,"default-order":_vm.defaultOrder,"load":_vm.load,"show-clone":"","bulk-actions":""},scopedSlots:_vm._u([{key:"extra-buttons",fn:function(){return [_c(VBtn,{attrs:{"color":"blue darken-2"},on:{"click":_vm.openDefaultConceptsModal}},[_vm._v(" Ver conceptos por defecto ")])]},proxy:true}])})],1)],1),_c(VDialog,{attrs:{"max-width":"800px","max-height":"80%"},model:{value:(_vm.dialogConcepts),callback:function ($$v) {_vm.dialogConcepts=$$v},expression:"dialogConcepts"}},[_c(VCard,[_c(VToolbar,[_c(VToolbarTitle,[_vm._v(" Conceptos Por Defecto ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Concepto")]),_c('th',{staticClass:"text-left"},[_vm._v("Es Salarial")]),_c('th',{staticClass:"text-left"},[_vm._v("Valor")])])]),_c('tbody',_vm._l((_vm.defaultConcepts),function(concept){return _c('tr',{key:concept.id},[_c('td',[_vm._v(_vm._s(concept.name))]),_c('td',[_vm._v(_vm._s(concept.is_salarial ? "SI" : "NO"))]),_c('td',[_vm._v(_vm._s(concept.value ? _vm.$options.filters.money(concept.value) : "--"))])])}),0)]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }