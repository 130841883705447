<template>
	<v-container fluid>
		<v-card>
			<v-card-text>
				<crud
					:model="'Concept'"
					create-permission="Agregar Conceptos"
					edit-permission="Editar Conceptos"
					delete-permission="Eliminar Conceptos"
					resource="concepts"
					:filters="filters"
					:headers="headers"
					:default-order="defaultOrder"
					:load="load"
					show-clone
					bulk-actions
				>
					<template #extra-buttons>
						<v-btn color="blue darken-2" @click="openDefaultConceptsModal"> Ver conceptos por defecto </v-btn>
					</template>
				</crud>
			</v-card-text>
		</v-card>
		<v-dialog v-model="dialogConcepts" max-width="800px" max-height="80%">
			<v-card>
				<v-toolbar>
					<v-toolbar-title> Conceptos Por Defecto </v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon><v-icon>mdi-close</v-icon></v-btn>
				</v-toolbar>
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">Concepto</th>
									<th class="text-left">Es Salarial</th>
									<th class="text-left">Valor</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="concept in defaultConcepts" :key="concept.id">
									<td>{{ concept.name }}</td>
									<td>{{ concept.is_salarial ? "SI" : "NO" }}</td>
									<td>{{ concept.value ? $options.filters.money(concept.value) : "--" }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
import model from "@/models/concepts";
import { basic_concepts_devengables, basic_concepts_deducibles } from "@/services/utils";
const { headers, filters, load, defaultOrder } = model;
export default {
	beforeMount() {},
	data() {
		return {
			item: null,
			dialogConcepts: false,
			headers,
			filters,
			load,
			defaultOrder,
			defaultConcepts: [...basic_concepts_devengables, ...basic_concepts_deducibles],
		};
	},
	methods: {
		openDefaultConceptsModal() {
			this.dialogConcepts = true;
		},
	},
};
</script>
